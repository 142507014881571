.pricing6-pricing23 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.pricing6-max-width {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.pricing6-section-title {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 800px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.pricing6-content {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 800px;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing6-tabs {
  display: flex;
  align-items: flex-start;
}
.pricing6-button {
  gap: var(--dl-space-space-halfunit);
  color: var(--dl-color-theme-neutral-light);
  width: 120px;
  height: 60px;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  justify-content: center;
  background-color: var(--dl-color-theme-primary1);
  border-top-left-radius: var(--dl-radius-radius-buttonradius);
  border-top-right-radius: 0;
  border-bottom-left-radius: var(--dl-radius-radius-buttonradius);
  border-bottom-right-radius: 0;
}
.pricing6-button01 {
  gap: var(--dl-space-space-halfunit);
  width: 120px;
  height: 60px;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-theme-primary1);
  border-style: solid;
  border-width: 1px;
  padding-left: var(--dl-space-space-unit);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-unit);
  justify-content: center;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.pricing6-button02 {
  gap: var(--dl-space-space-halfunit);
  width: 120px;
  height: 60px;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-color: var(--dl-color-theme-primary1);
  border-style: solid;
  border-width: 1px;
  justify-content: center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.pricing6-button03 {
  gap: var(--dl-space-space-halfunit);
  width: 120px;
  height: 60px;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  border-color: var(--dl-color-theme-primary1);
  border-style: solid;
  border-width: 1px;
  justify-content: center;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.pricing6-container {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  animation-name: fadeIn;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.pricing6-column {
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  border-color: var(--dl-color-theme-neutral-dark);
  border-style: solid;
  border-width: 1px;
}
.pricing6-price {
  gap: var(--dl-space-space-twounits);
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing6-price01 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing6-list {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.pricing6-list-item {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing6-list-item01 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing6-list-item02 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing6-button04 {
  width: 100%;
}
.pricing6-column1 {
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  border-color: var(--dl-color-theme-neutral-dark);
  border-style: solid;
  border-width: 1px;
  background-color: var(--dl-color-theme-accent1);
}
.pricing6-price02 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing6-price03 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing6-list1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.pricing6-list-item03 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing6-list-item04 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing6-list-item05 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing6-list-item06 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing6-button05 {
  width: 100%;
}
.pricing6-column2 {
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  border-color: var(--dl-color-theme-neutral-dark);
  border-style: solid;
  border-width: 1px;
  background-color: var(--dl-color-theme-accent2);
}
.pricing6-price04 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing6-price05 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing6-list2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.pricing6-list-item07 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing6-list-item08 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing6-list-item09 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing6-list-item10 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing6-button06 {
  width: 100%;
}
.pricing6-container1 {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  animation-name: fadeIn;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.pricing6-column3 {
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  border-color: var(--dl-color-theme-neutral-dark);
  border-style: solid;
  border-width: 1px;
}
.pricing6-price06 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing6-price07 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing6-list3 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.pricing6-list-item11 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing6-list-item12 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing6-list-item13 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing6-button07 {
  width: 100%;
}
.pricing6-column4 {
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  border-color: var(--dl-color-theme-neutral-dark);
  border-style: solid;
  border-width: 1px;
  background-color: var(--dl-color-theme-accent1);
}
.pricing6-price08 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing6-price09 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing6-list4 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.pricing6-list-item14 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing6-list-item15 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing6-list-item16 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing6-list-item17 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing6-button08 {
  width: 100%;
}
.pricing6-column5 {
  flex: 1;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  border-color: var(--dl-color-theme-neutral-dark);
  border-style: solid;
  border-width: 1px;
  background-color: var(--dl-color-theme-accent2);
}
.pricing6-price10 {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing6-price11 {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}
.pricing6-list5 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}
.pricing6-list-item18 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing6-list-item19 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing6-list-item20 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing6-list-item21 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}
.pricing6-button09 {
  width: 100%;
}
.pricing6-text053 {
  display: inline-block;
}
.pricing6-text054 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.pricing6-text055 {
  display: inline-block;
}
.pricing6-text056 {
  display: inline-block;
}
.pricing6-text057 {
  display: inline-block;
}
.pricing6-text058 {
  display: inline-block;
}
.pricing6-text059 {
  display: inline-block;
  text-align: center;
}
.pricing6-text060 {
  display: inline-block;
}
.pricing6-text061 {
  display: inline-block;
}
.pricing6-text062 {
  display: inline-block;
}
.pricing6-text063 {
  display: inline-block;
}
.pricing6-text064 {
  display: inline-block;
}
.pricing6-text065 {
  display: inline-block;
}
.pricing6-text066 {
  display: inline-block;
  font-size: 48px;
}
.pricing6-text067 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.pricing6-text068 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.pricing6-text069 {
  display: inline-block;
  font-size: 48px;
}
.pricing6-text070 {
  display: inline-block;
}
.pricing6-text071 {
  display: inline-block;
}
.pricing6-text072 {
  display: inline-block;
}
.pricing6-text073 {
  display: inline-block;
}
.pricing6-text074 {
  display: inline-block;
}
.pricing6-text075 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.pricing6-text076 {
  display: inline-block;
  font-size: 48px;
}
.pricing6-text077 {
  display: inline-block;
}
.pricing6-text078 {
  display: inline-block;
}
.pricing6-text079 {
  display: inline-block;
  font-size: 48px;
}
.pricing6-text080 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.pricing6-text081 {
  display: inline-block;
}
.pricing6-text082 {
  display: inline-block;
  font-size: 48px;
}
.pricing6-text083 {
  display: inline-block;
}
.pricing6-text084 {
  display: inline-block;
}
.pricing6-text085 {
  display: inline-block;
}
.pricing6-text086 {
  display: inline-block;
}
.pricing6-text087 {
  display: inline-block;
}
.pricing6-text088 {
  display: inline-block;
}
.pricing6-text089 {
  display: inline-block;
  text-align: center;
}
.pricing6-text090 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}
.pricing6-text091 {
  display: inline-block;
}
.pricing6-text092 {
  display: inline-block;
}
.pricing6-text093 {
  display: inline-block;
}
.pricing6-text094 {
  display: inline-block;
}
.pricing6-text095 {
  display: inline-block;
  text-align: center;
}
.pricing6-text098 {
  display: inline-block;
}
.pricing6-text099 {
  display: inline-block;
}
.pricing6-text100 {
  display: inline-block;
}
.pricing6-text101 {
  display: inline-block;
  font-size: 48px;
}
.pricing6-text102 {
  display: inline-block;
}
.pricing6-text103 {
  display: inline-block;
}
.pricing6-root-class-name {
  display: none;
}
@media(max-width: 1200px) {
  .pricing6-root-class-name {
    display: none;
  }
}
@media(max-width: 991px) {
  .pricing6-container {
    flex-direction: column;
  }
  .pricing6-container1 {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .pricing6-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .pricing6-button05 {
    width: 100%;
  }
  .pricing6-button06 {
    width: 100%;
  }
}
