.home-doctors {
  display: flex;
  overflow: hidden; 
  max-width: 100%; 
}

.home-list1 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden; 
}

.doctor-doctor {
  width: 270px;
  max-width: 270px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}

.doctor-image {
  width: 220px;
  object-fit: cover;
}

@media (max-width: 1024px) {
  .home-doctors {
    max-width: calc(270px * 3); 
  }
}

@media (max-width: 767px) {
  .home-doctors {
    max-width: calc(270px * 2); 
  }

  .doctor-doctor {
    max-width: 200px;
  }
  .doctor-image{
width:175px
  }
}
