.faq14-faq8 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}

.faq14-max-width {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.faq14-container {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}

.faq14-section-title {
  width: 100%;
  align-self: center;
  align-items: center;
}

.faq14-list {
  gap: var(--dl-space-space-halfunit);
  width: 100%;
  align-self: center;
  align-items: flex-start;
}

.faq14-faq1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.faq14-trigger {
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.faq14-trigger:hover {
  fill: var(--dl-color-theme-secondary2);
  color: var(--dl-color-theme-secondary2);
  background-color: var(--dl-color-theme-primary2);
}

.faq14-icons-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}

.faq14-icon {
  width: 24px;
  height: 24px;
}

.faq14-icon02 {
  width: 24px;
  height: 24px;
}

.faq14-container03 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}

.faq14-faq2 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.faq14-trigger1 {
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.faq14-trigger1:hover {
  fill: var(--dl-color-theme-secondary2);
  color: var(--dl-color-theme-secondary2);
  background-color: var(--dl-color-theme-primary2);
}

.faq14-icons-container1 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}

.faq14-icon04 {
  width: 24px;
  height: 24px;
}

.faq14-icon06 {
  width: 24px;
  height: 24px;
}

.faq14-container06 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}

.faq14-faq3 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.faq14-trigger2 {
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.faq14-trigger2:hover {
  fill: var(--dl-color-theme-secondary2);
  color: var(--dl-color-theme-secondary2);
  background-color: var(--dl-color-theme-primary2);
}

.faq14-icons-container2 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}

.faq14-icon08 {
  width: 24px;
  height: 24px;
}

.faq14-icon10 {
  width: 24px;
  height: 24px;
}

.faq14-container09 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}

.faq14-faq4 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.faq14-trigger3 {
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.faq14-trigger3:hover {
  fill: var(--dl-color-theme-secondary2);
  color: var(--dl-color-theme-secondary2);
  background-color: var(--dl-color-theme-primary2);
}

.faq14-icons-container3 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}

.faq14-icon12 {
  width: 24px;
  height: 24px;
}

.faq14-icon14 {
  width: 24px;
  height: 24px;
}

.faq14-container12 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}

.faq14-faq5 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.faq14-trigger4 {
  cursor: pointer;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.faq14-trigger4:hover {
  fill: var(--dl-color-theme-secondary2);
  color: var(--dl-color-theme-secondary2);
  background-color: var(--dl-color-theme-primary2);
}

.faq14-icons-container4 {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}

.faq14-icon16 {
  width: 24px;
  height: 24px;
}

.faq14-icon18 {
  width: 24px;
  height: 24px;
}

.faq14-container15 {
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: stretch;
  align-items: flex-start;
  animation-name: fadeIn;
  flex-direction: row;
  animation-delay: 0s;
  justify-content: space-between;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}

.faq14-content {
  align-self: center;
}

.faq14-content1 {
  gap: 16px;
  display: flex;
  align-self: stretch;
  flex-direction: column;
}

.faq14-text11 {
  display: inline-block;
  text-align: center;
}

.faq14-text12 {
  display: inline-block;
  text-align: center;
  padding-left: 10%;
}

.faq14-text13 {
  display: inline-block;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}

.faq14-text14 {
  display: inline-block;
}

.faq14-text15 {
  display: inline-block;
}

.faq14-text16 {
  display: inline-block;
  text-align: center;
}

.faq14-text17 {
  display: inline-block;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}

.faq14-text18 {
  display: inline-block;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}

.faq14-text19 {
  display: inline-block;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}

.faq14-text20 {
  display: inline-block;
  text-align: center;
}

.faq14-text23 {
  display: inline-block;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}

@media(max-width: 991px) {
  .faq14-container {
    flex-direction: column;
  }
}

@media(max-width: 479px) {
  .faq14-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .faq14-text12 {
    font-size: 28px;
  }
}