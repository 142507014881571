.pricing-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}

.pricing-header {
  width: 100%;
  display: flex;
  justify-content: center;
}

.pricing-navbar {
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: 1440px;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
}

.pricing-left {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  justify-content: center;
}

.pricing-logo {
  width: 165px;
  object-fit: cover;
}

.pricing-links {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  justify-content: center;
}

.pricing-link {
  cursor: pointer;
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
}

.pricing-link:hover {
  opacity: 0.5;
}

.pricing-link01 {
  cursor: pointer;
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
}

.pricing-link01:hover {
  opacity: 0.5;
}

.pricing-link02 {
  cursor: pointer;
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
  line-height: 24px;
}

.pricing-link02:hover {
  opacity: 0.5;
}

.pricing-link03 {
  cursor: pointer;
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
}

.pricing-link03:hover {
  opacity: 0.5;
}

.pricing-right {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.pricing-phone {
  gap: var(--dl-space-space-halfunit);
  cursor: pointer;
  display: flex;
  align-items: center;
  border-width: 0px;
  flex-direction: row;
  justify-content: center;
}

.pricing-image {
  width: 18px;
  object-fit: cover;
}

.pricing-text {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

.pricing-book {
  text-decoration: none;
}

.pricing-image1 {
  width: 18px;
  object-fit: cover;
}

.pricing-text001 {
  font-family: Inter;
}

.pricing-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}

.pricing-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.pricing-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 1000;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}

.pricing-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.pricing-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}

.pricing-image2 {
  height: 2rem;
}

.pricing-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pricing-icon2 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.pricing-nav1 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.pricing-text002 {
  margin-bottom: var(--dl-space-space-unit);
}

.pricing-text003 {
  margin-bottom: var(--dl-space-space-unit);
}

.pricing-text004 {
  margin-bottom: var(--dl-space-space-unit);
}

.pricing-text005 {
  margin-bottom: var(--dl-space-space-unit);
}

.pricing-book1 {
  text-decoration: none;
}

.pricing-image3 {
  width: 18px;
  object-fit: cover;
}

.pricing-text006 {
  font-family: Inter;
}

.pricing-text007 {
  display: inline-block;
}

.pricing-text008 {
  display: inline-block;
}

.pricing-text009 {
  display: inline-block;
}

.pricing-text010 {
  display: inline-block;
}

.pricing-text011 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}

.pricing-text012 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}

.pricing-text013 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}

.pricing-text014 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}

.pricing-text015 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}

.pricing-text016 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}

.pricing-text017 {
  display: inline-block;
  text-align: center;
}

.pricing-text018 {
  display: inline-block;
  text-align: center;
}

.pricing-text021 {
  display: inline-block;
  text-align: center;
}

.pricing-text022 {
  display: inline-block;
  font-size: 48px;
}

.pricing-text023 {
  display: inline-block;
  font-size: 48px;
}

.pricing-text024 {
  display: inline-block;
  font-size: 48px;
}

.pricing-text025 {
  display: inline-block;
}

.pricing-text026 {
  display: inline-block;
  font-size: 48px;
}

.pricing-text027 {
  display: inline-block;
}

.pricing-text028 {
  display: inline-block;
}

.pricing-text029 {
  display: inline-block;
  font-size: 48px;
}

.pricing-text030 {
  display: inline-block;
}

.pricing-text031 {
  display: inline-block;
}

.pricing-text032 {
  display: inline-block;
  font-size: 48px;
}

.pricing-text033 {
  display: inline-block;
}

.pricing-text034 {
  display: inline-block;
}

.pricing-text035 {
  display: inline-block;
}

.pricing-text036 {
  display: inline-block;
}

.pricing-text037 {
  display: inline-block;
}

.pricing-text038 {
  display: inline-block;
}

.pricing-text039 {
  display: inline-block;
}

.pricing-text040 {
  display: inline-block;
}

.pricing-text041 {
  display: inline-block;
}

.pricing-text042 {
  display: inline-block;
}

.pricing-text043 {
  display: inline-block;
}

.pricing-text044 {
  display: inline-block;
}

.pricing-text045 {
  display: inline-block;
}

.pricing-text046 {
  display: inline-block;
}

.pricing-text047 {
  display: inline-block;
}

.pricing-text048 {
  display: inline-block;
}

.pricing-text049 {
  display: inline-block;
}

.pricing-text050 {
  display: inline-block;
}

.pricing-text051 {
  display: inline-block;
}

.pricing-text052 {
  display: inline-block;
}

.pricing-text053 {
  display: inline-block;
}

.pricing-text054 {
  display: inline-block;
}

.pricing-text055 {
  display: inline-block;
}

.pricing-text056 {
  display: inline-block;
}

.pricing-text057 {
  display: inline-block;
}

.pricing-text058 {
  display: inline-block;
}

.pricing-text059 {
  display: inline-block;
}

.pricing-text060 {
  display: inline-block;
}

.pricing-text061 {
  display: inline-block;
}

.pricing-text062 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}

.pricing-text063 {
  display: inline-block;
  text-align: center;
}

.pricing-text064 {
  display: inline-block;
  text-align: center;
}

.pricing-text067 {
  display: inline-block;
  text-align: center;
}

.pricing-text068 {
  display: inline-block;
  font-size: 48px;
}

.pricing-text069 {
  display: inline-block;
}

.pricing-text070 {
  display: inline-block;
}

.pricing-text071 {
  display: inline-block;
}

.pricing-text072 {
  display: inline-block;
}

.pricing-text073 {
  display: inline-block;
}

.pricing-text074 {
  display: inline-block;
}

.pricing-text075 {
  display: inline-block;
}

.pricing-text076 {
  display: inline-block;
}

.pricing-text077 {
  display: inline-block;
}

.pricing-text078 {
  display: inline-block;
}

.pricing-text079 {
  display: inline-block;
}

.pricing-text080 {
  display: inline-block;
}

.pricing-text081 {
  display: inline-block;
}

.pricing-text082 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}

.pricing-text083 {
  display: inline-block;
  text-align: center;
}

.pricing-text084 {
  display: inline-block;
  text-align: center;
}

.pricing-text087 {
  display: inline-block;
  text-align: center;
}

.pricing-text088 {
  display: inline-block;
  font-size: 48px;
}

.pricing-text089 {
  display: inline-block;
}

.pricing-text090 {
  display: inline-block;
}

.pricing-text091 {
  display: inline-block;
}

.pricing-text092 {
  display: inline-block;
}

.pricing-text093 {
  display: inline-block;
}

.pricing-text094 {
  display: inline-block;
}

.pricing-text095 {
  display: inline-block;
}

.pricing-text096 {
  display: inline-block;
}

.pricing-text097 {
  display: inline-block;
}

.pricing-text098 {
  display: inline-block;
}

.pricing-text099 {
  display: inline-block;
}

.pricing-text100 {
  display: inline-block;
}

.pricing-text101 {
  display: inline-block;
}

.pricing-text102 {
  display: inline-block;
  text-align: center;
}

.pricing-text103 {
  display: inline-block;
  text-align: center;
}

.pricing-text104 {
  display: inline-block;
  text-align: center;
}

.pricing-text105 {
  display: inline-block;
  text-align: center;
}

.pricing-text106 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}

.pricing-text107 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}

.pricing-text108 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}

.pricing-text109 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}

.pricing-text110 {
  display: inline-block;
}

.pricing-text111 {
  display: inline-block;
}

.pricing-text112 {
  display: inline-block;
}

.pricing-text113 {
  display: inline-block;
}

.pricing-text114 {
  display: inline-block;
}

.pricing-text115 {
  display: inline-block;
}

.pricing-text116 {
  display: inline-block;
  text-align: center;
}

.pricing-text117 {
  display: inline-block;
  text-align: center;
}

.pricing-text120 {
  display: inline-block;
  text-align: center;
}

.pricing-text121 {
  display: inline-block;
  text-align: center;
}

.pricing-text122 {
  display: inline-block;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}

.pricing-text123 {
  display: inline-block;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}

.pricing-text124 {
  display: inline-block;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}

.pricing-text125 {
  display: inline-block;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}

.pricing-text126 {
  display: inline-block;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}

.pricing-text127 {
  display: inline-block;
}

.pricing-text128 {
  display: inline-block;
}

.pricing-text129 {
  display: inline-block;
  text-align: center;
}

.pricing-text130 {
  display: inline-block;
  text-align: center;
}

.pricing-footer {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 120px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
  background-color: #000000;
}

.pricing-left1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
}

.pricing-brand {
  gap: var(--dl-space-space-unit);
  display: flex;
  max-width: 400px;
  align-items: flex-start;
  flex-direction: column;
}

.pricing-image4 {
  width: 165px;
  object-fit: cover;
}

.pricing-text131 {
  color: rgb(255, 255, 255);
  font-size: 14px;
  line-height: 21px;
}

.pricing-socials {
  gap: var(--dl-space-space-unit);
  display: flex;
  max-width: 400px;
  align-items: flex-start;
  flex-direction: row;
}

.pricing-image5 {
  width: 24px;
  object-fit: cover;
}

.pricing-image6 {
  width: 24px;
  object-fit: cover;
}

.pricing-image7 {
  width: 24px;
  object-fit: cover;
}

.pricing-legal {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.pricing-copyright {
  color: rgb(255, 255, 255);
  font-size: 12px;
}

.pricing-right1 {
  gap: 140px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}

.pricing-list {
  gap: var(--dl-space-space-unit);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.pricing-header1 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-weight: 600;
}

.pricing-links1 {
  gap: 12px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.pricing-link04 {
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: 0.3s;
}

.pricing-link04:hover {
  opacity: 0.5;
}

.pricing-link05 {
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: 0.3s;
}

.pricing-link05:hover {
  opacity: 0.5;
}

.pricing-link06 {
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: 0.3s;
}

.pricing-link06:hover {
  opacity: 0.5;
}

.pricing-link07 {
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: 0.3s;
}

.pricing-link07:hover {
  opacity: 0.5;
}

.pricing-link08 {
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: 0.3s;
}

.pricing-link08:hover {
  opacity: 0.5;
}

.pricing-list1 {
  gap: var(--dl-space-space-unit);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.pricing-header2 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-weight: 600;
}

.pricing-links2 {
  gap: 12px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.pricing-link09 {
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: 0.3s;
}

.pricing-link09:hover {
  opacity: 0.5;
}

.pricing-link10 {
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: 0.3s;
}

.pricing-link10:hover {
  opacity: 0.5;
}

.pricing-link11 {
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: 0.3s;
}

.pricing-link11:hover {
  opacity: 0.5;
}

.pricing-link12 {
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: 0.3s;
}

.pricing-link12:hover {
  opacity: 0.5;
}

.pricing-list2 {
  gap: var(--dl-space-space-unit);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.pricing-header3 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-weight: 600;
}

.pricing-links3 {
  gap: 12px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.pricing-link13 {
  color: rgb(255, 255, 255);
  width: 215px;
  cursor: pointer;
  transition: 0.3s;
}

.pricing-link13:hover {
  opacity: 0.5;
}

.pricing-link14 {
  color: rgb(255, 255, 255);
  width: 215px;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
}

.pricing-link14:hover {
  opacity: 0.5;
}

.pricing-link15 {
  color: rgb(255, 255, 255);
  width: 215px;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
}

.pricing-link15:hover {
  opacity: 0.5;
}

.pricing-legal1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: none;
  align-items: flex-start;
  flex-direction: row;
}

.pricing-row {
  display: flex;
}

.pricing-copyright5 {
  color: rgb(255, 255, 255);
  font-size: 12px;
}

.pricing-text132 {
  display: inline-block;
  font-style: normal;
  font-weight: 600;
}

.pricing-text133 {
  display: inline-block;
  text-align: center;
}

.pricing-text134 {
  display: inline-block;
  text-align: center;
}

.pricing-text137 {
  display: inline-block;
  text-align: center;
}

.pricing-text138 {
  display: inline-block;
  font-size: 48px;
}

.pricing-text139 {
  display: inline-block;
}

.pricing-text140 {
  display: inline-block;
}

.pricing-text141 {
  display: inline-block;
}

.pricing-text142 {
  display: inline-block;
}

.pricing-text143 {
  display: inline-block;
}

.pricing-text144 {
  display: inline-block;
}

.pricing-text145 {
  display: inline-block;
}

.pricing-text146 {
  display: inline-block;
}

.pricing-text147 {
  display: inline-block;
}

.pricing-text148 {
  display: inline-block;
}

.pricing-text149 {
  display: inline-block;
}

.pricing-text150 {
  display: inline-block;
}

.pricing-text151 {
  display: inline-block;
}

.pricing-text152 {
  display: inline-block;
}

.pricing-text153 {
  display: inline-block;
}

.pricing-text154 {
  display: inline-block;
}

.pricing-text155 {
  display: inline-block;
}

.pricing-text156 {
  display: inline-block;
}

.pricing-text157 {
  display: inline-block;
}

.pricing-text158 {
  display: inline-block;
}

.pricing-text159 {
  display: inline-block;
}

.pricing-text160 {
  display: inline-block;
}

.pricing-text161 {
  display: inline-block;
}

.pricing-text162 {
  display: inline-block;
}

.pricing-text163 {
  display: inline-block;
  align-self: center;
  text-align: center;
}

.pricing-text164 {
  display: inline-block;
}

.pricing-text165 {
  display: inline-block;
}

.pricing-text166 {
  display: inline-block;
}

.pricing-text167 {
  display: inline-block;
}

.pricing-text168 {
  display: inline-block;
}

.pricing-text169 {
  display: inline-block;
  align-self: center;
  text-align: center;
}

.pricing-text170 {
  display: inline-block;
}

.pricing-text171 {
  display: inline-block;
}

.pricing-text172 {
  display: inline-block;
}

.pricing-text173 {
  display: inline-block;
}

.pricing-text174 {
  display: inline-block;
}

.pricing-text175 {
  display: inline-block;
}

.pricing-text176 {
  display: inline-block;
  align-self: center;
  text-align: center;
}

.pricing-text177 {
  display: inline-block;
}

.pricing-text178 {
  display: inline-block;
}

.pricing-text179 {
  display: inline-block;
}

@media(max-width: 991px) {
  .pricing-navbar {
    z-index: 1000;
  }

  .pricing-links {
    display: none;
  }

  .pricing-right {
    display: none;
  }

  .pricing-burger-menu {
    display: flex;
    padding: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-round);
    background-color: #2461FF;
  }

  .pricing-icon {
    fill: white;
  }

  .pricing-footer {
    gap: 100px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }

  .pricing-left1 {
    padding-right: 0px;
  }

  .pricing-socials {
    display: none;
  }

  .pricing-legal {
    display: none;
  }

  .pricing-right1 {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
  }

  .pricing-legal1 {
    gap: var(--dl-space-space-twounits);
    display: flex;
    flex-direction: column;
  }

  .pricing-row {
    gap: var(--dl-space-space-oneandhalfunits);
  }
}

@media(max-width: 767px) {
  .pricing-navbar {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-unit);
  }

  .pricing-burger-menu {
    display: flex;
  }

  .pricing-footer {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }

  .pricing-image4 {
    width: 110px;
  }

  .pricing-text131 {
    font-size: 12px;
    line-height: 18px;
  }
}

@media(max-width: 479px) {
  .pricing-navbar {
    padding: var(--dl-space-space-unit);
  }

  .pricing-mobile-menu {
    padding: 16px;
  }

  .pricing-text062 {
    text-align: center;
  }

  .pricing-text068 {
    text-align: center;
  }

  .pricing-text070 {
    text-align: center;
  }

  .pricing-text082 {
    text-align: center;
  }

  .pricing-text088 {
    text-align: center;
  }

  .pricing-text090 {
    text-align: center;
  }

  .pricing-text132 {
    text-align: center;
  }

  .pricing-text138 {
    text-align: center;
  }

  .pricing-text140 {
    text-align: center;
  }
}