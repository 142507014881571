.pricing3-pricing4 {
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}

.card-image {
  width: 70px;
}

.pricing3-max-width {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.pricing3-section-title {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 800px;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}

.pricing3-content {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
}

.pricing3-content1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: 800px;
  align-items: flex-start;
  flex-shrink: 0;
  border-color: var(--dl-color-theme-neutral-dark);
  border-style: solid;
  border-width: 3px;
  flex-direction: column;
}

.pricing3-price-title {
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  justify-content: space-between;
}

.pricing3-price-title1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.pricing3-icon {
  width: 48px;
  height: 48px;
}

.pricing3-title {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.pricing3-content2 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
}

.pricing3-content3 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
  padding-top: var(--dl-space-space-halfunit);
  padding-bottom: var(--dl-space-space-halfunit);
}

.pricing3-list {
  gap: var(--dl-space-space-unit);
  flex: 1;
  width: auto;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}

.pricing3-list-item {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}

.pricing3-list-item01 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}

.pricing3-list-item02 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}

.pricing3-list-item03 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}

.pricing3-list-item04 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}

.pricing3-list1 {
  gap: var(--dl-space-space-unit);
  flex: 1;
  width: auto;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-shrink: 0;
  flex-direction: column;
}

.pricing3-list-item05 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}

.pricing3-list-item06 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}

.pricing3-list-item07 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}

.pricing3-list-item08 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}

.pricing3-list-item09 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  flex-shrink: 0;
}

.pricing3-button {
  width: 100%;
}

.pricing3-text18 {
  display: inline-block;
}

.pricing3-text19 {
  display: inline-block;
}

.pricing3-text20 {
  display: inline-block;
}

.pricing3-text21 {
  display: inline-block;
}

.pricing3-text22 {
  display: inline-block;
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
}

.pricing3-text23 {
  display: inline-block;
}

.pricing3-text24 {
  display: inline-block;
  text-align: center;
}

.pricing3-text25 {
  display: inline-block;
  text-align: center;
}

.pricing3-text26 {
  display: inline-block;
}

.pricing3-text27 {
  display: inline-block;
}

.pricing3-text28 {
  display: inline-block;
}

.pricing3-text29 {
  display: inline-block;
  font-size: 48px;
}

.pricing3-text30 {
  display: inline-block;
}

.pricing3-text31 {
  display: inline-block;
}

.pricing3-text32 {
  display: inline-block;
}

.pricing3-text33 {
  display: inline-block;
}

.pricing3-text34 {
  display: inline-block;
}

.pricing3-text35 {
  display: inline-block;
  text-align: center;
}

.pricing3-text36 {
  font-size: 20px;
  font-weight: 200;
}

.pricing3-text99 {
  display: flex;
  flex-direction: column;
}

@media(max-width: 479px) {
  .pricing3-text36 {
    font-size: 15px;
  }

  .pricing3-list-item {
    /* display: none; */
  }

  .pricing3-text23 {
    /* display: none; */
  }

  .pricing3-text99 {
    flex-direction: row;
    align-items: center;
    gap: 8px;
    font-size: 15px;
  }

  .pricing3-max-width {
    gap: var(--dl-space-space-oneandhalfunits);
  }

  .pricing3-price-title {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 10px
  }

  .pricing3-price-title1 {
    align-items: center;
    justify-content: center;
    gap: 5px
  }

  .pricing3-title {
    align-items: center;
    justify-content: center;
    gap: 5px
  }

  .pricing3-content3 {
    flex-direction: column;
    gap: 7px;
    padding-top: 5px;
  }

  .pricing3-list {
    width: 100%;
    gap: 5px
  }

  .pricing3-list1 {
    width: 100%;
    gap: 7px;
  }

  .pricing3-button {
    width: 100%;
  }

  .pricing3-text18 {
    text-align: center;
    font-size: 15px;
  }

  .pricing3-text22 {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
  }

  .pricing3-text29 {
    text-align: center;
    font-size: 28px;
    line-height: 1.3;
    font-weight: 700;
  }

  .pricing3-content1 {
    padding: 25px;
    gap: var(--dl-space-space-oneunit);
    border-width: 1px;
  }

  .pricing3-content2 {
    gap: 1px;
    margin-top: 5px;
  }

  .card-image {
    width: 50px;
    display: none;
  }
}