.whyChooseUs-whyChooseUs {
    gap: var(--dl-space-space-oneandhalfunits);
    display: flex;
    position: relative;
    align-items: flex-start;
    padding-top: var(--dl-space-space-twounits);
    border-color: #B4B9C6;
    flex-direction: column;
    border-top-width: 1px;
}

.whyChooseUs-heading {
    gap: var(--dl-space-space-unit);
    display: flex;
    align-items: flex-start;
    flex-direction: row;
}

.whyChooseUs-header {
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 36px;
}

.whyChooseUs-caption {
    max-width: 385px;
    line-height: 24px;
}

.whyChooseUs-text {
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}

.whyChooseUs-image {
    width: 30px;
    height: 30px;
}

@media(max-width: 767px) {
    .whyChooseUs-whyChooseUs {
        padding-top: var(--dl-space-space-oneandhalfunits);
    }

    .whyChooseUs-header {
        font-size: 20px;
        line-height: 24px;
    }

    .whyChooseUs-caption {
        font-size: 14px;
        line-height: 21px;
    }
}