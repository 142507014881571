.content-list6-container {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}

.content-list6-max-width {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
}

.content-list6-container1 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  padding-top: 17px;
  flex-direction: column;
  justify-content: center;
}

.content-list6-container2 {
  width: auto;
  padding: var(--dl-space-space-twounits);
  align-items: flex-start;
}

.content-list6-container3 {
  align-self: stretch;
  align-items: flex-start;
}

.content-list6-ul {
  align-items: flex-start;
}

.content-list6-li {
  align-items: flex-start;
}

.content-list6-ul1 {
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
}

.content-list6-container4 {
  align-self: stretch;
  align-items: flex-start;
}

.content-list6-li03 {
  align-items: flex-start;
}

.content-list6-ul3 {
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
}

.content-list6-container5 {
  align-self: stretch;
  align-items: flex-start;
}

.content-list6-ul4 {
  align-items: flex-start;
}

.content-list6-li09 {
  align-items: flex-start;
}

.content-list6-ul5 {
  align-items: flex-start;
  padding-left: var(--dl-space-space-fiveunits);
}

.content-list6-text06 {
  display: inline-block;
}

.content-list6-text07 {
  display: inline-block;
}

.content-list6-text08 {
  display: inline-block;
  align-self: center;
  text-align: center;
}

.content-list6-text09 {
  display: inline-block;
}

.content-list6-text10 {
  display: inline-block;
}

.content-list6-text11 {
  display: inline-block;
}

.content-list6-text12 {
  display: inline-block;
}

.content-list6-text13 {
  display: inline-block;
}

.content-list6-text14 {
  display: inline-block;
}

.content-list6-text15 {
  display: inline-block;
}

.content-list6-text16 {
  display: inline-block;
}

.content-list6-text17 {
  display: inline-block;
}

.content-list6-text18 {
  display: inline-block;
}

.content-list6-text19 {
  display: inline-block;
}

.content-list6-text20 {
  display: inline-block;
  align-self: center;
  text-align: center;
}

.content-list6-text21 {
  display: inline-block;
}

.content-list6-text22 {
  display: inline-block;
}

.content-list6-text23 {
  display: inline-block;
}

.content-list6-text24 {
  display: inline-block;
}

.content-list6-text25 {
  display: inline-block;
}

.content-list6-text26 {
  display: inline-block;
  text-align: center;
}

.content-list6-text27 {
  display: inline-block;
}

.content-list6-text28 {
  display: inline-block;
}

.content-list6-text29 {
  display: inline-block;
}

.content-list6-text30 {
  display: inline-block;
}

.content-list6-text31 {
  display: inline-block;
  align-self: center;
  text-align: center;
}

.content-list6-text32 {
  display: inline-block;
}

.content-list6-text33 {
  display: inline-block;
}

@media(max-width: 1200px) {
  .content-list6-root-class-name {
    display: none;
  }
}

@media(max-width: 767px) {
  .content-list6-max-width {
    align-items: center;
    flex-direction: column;
  }

  .content-list6-container1 {
    align-self: center;
  }

  .content-list6-container2 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .content-list6-ul1 {
    padding-left: var(--dl-space-space-threeunits);
  }

  .content-list6-ul3 {
    padding-left: var(--dl-space-space-threeunits);
  }

  .content-list6-ul5 {
    padding-left: var(--dl-space-space-threeunits);
  }
}

@media(max-width: 479px) {
  .content-list6-max-width {
    flex-direction: column;
  }

  .content-list6-container1 {
    align-self: center;
  }

  .content-list6-ul1 {
    padding-left: var(--dl-space-space-oneandhalfunits);
  }

  .content-list6-ul3 {
    padding-left: var(--dl-space-space-oneandhalfunits);
  }

  .content-list6-ul5 {
    padding-left: var(--dl-space-space-oneandhalfunits);
  }
}